import * as yup from "yup";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAuth } from "../../contexts/Auth";
import { useState } from "react";

import {
  ContentContact,
  ContentContactContainer,
  ContentContactHeader,
  ContentForm,
  StyledInputMask,
} from "./styles";

import { FaUser } from "react-icons/fa";
import { Spinner } from "@chakra-ui/react";
import { apiAuth } from "../../services/api";
import { useCredits } from "../../contexts/Credits";

export const ModalAddContactContent = ({
  id = "modal-add-contact",
  onClose = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const { createsCoinWallet } = useCredits();

  const { data, updateProfile } = useAuth();

  const contactSchema = yup.object().shape({
    contato: yup.string().required("Por favor, digite o seu nome"),
  });

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(contactSchema),
  });

  const handleUpdateUser = async (userData) => {
    const content = {
      1: "Produtos Minerados",
      2: "Preparação do Anúncio",
      3: "Produtos em Fase de Teste",
      4: "Produtos em Escala",
      5: "Produtos Reprovados",
      id_login: data.id,
    };

    const categoriesCredit = [
      "ver_produtos",
      "adicionar_esteira",
      "importar_para_shopify",
      "gerar_copy",
      "gerar_nomes",
    ].join();

    const credits = [50, 50, 25, 25, 25].join();

    await updateProfile(userData, setLoading, data.id_planos, onClose);

    await createsCoinWallet(categoriesCredit, credits);

    await apiAuth.post(
      "/cadastro-de-novas-esteiras",
      {
        esteira: JSON.stringify(content),
      },
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  };

  return (
    <ContentContact id={id}>
      <div className="adjustment-modal">
        <ContentContactContainer>
          <ContentContactHeader>
            <div>
              <h2>Atualizar Contato</h2>
              <FaUser size="1.5rem" color="#fff" />
            </div>
          </ContentContactHeader>

          <ContentForm onSubmit={handleSubmit(handleUpdateUser)}>
            <h4>
              Atualize o seu contato para uma melhor comunicação com nosso time
            </h4>

            <label htmlFor="contato">Contato</label>
            <Controller
              name="contato"
              control={control}
              render={({ field }) => (
                <StyledInputMask
                  {...field}
                  {...register("contato")}
                  type="text"
                  mask="(99) 9 9999-9999"
                  maskChar=""
                  isInvalid={!!errors.contato}
                  placeholder="Contato"
                  alwaysShowMask={false}
                />
              )}
            />

            <button type="submit">
              {loading ? <Spinner /> : "Salvar dados"}
            </button>
          </ContentForm>
        </ContentContactContainer>
      </div>
    </ContentContact>
  );
};
