import playdropIcon from "../../assets/icons/playdrop-icon.svg";

import { useAuth } from "../../contexts/Auth";
import { useNavigate } from "react-router-dom";
import { SuppliersCardContent } from "./styles";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import { useEffect, useState } from "react";

import { GiBrazilFlag } from "react-icons/gi";
import { TbWorldCheck } from "react-icons/tb";

export const SuppliersCard = ({
  productAdId,
  isSuppliers,
  image,
  video,
  productName,
  supplierLink,
  supplierInternationalLink,
}) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { data } = useAuth();
  const { vendors, loadVendors } = useProductsClient();

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      loadVendors(productAdId);

      setLoading(false);
    }, 2000);

    // eslint-disable-next-line
  }, []);

  const isVideo = (filename) => {
    const videoExtensions = ["mp4", "avi", "mov", "mkv"]; // Adicione outras extensões de vídeo se necessário
    const extension = filename.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  return (
    <SuppliersCardContent>
      {loading ? (
        <div className="is-loading">
          🤯 Carregando uma lista de fornecedores incríveis e personalizados pra
          você 🤯
        </div>
      ) : (
        <>
          {isSuppliers === "international" &&
          supplierInternationalLink !== "Sem fornecedor internacional" ? (
            data.id_planos !== 4 ? (
              <li className="brazilian-product">
                <div className="icon">
                  <div className="brazilian-icon">
                    <TbWorldCheck size="2rem" color="#3058a4" />
                  </div>

                  <h4>{productName}</h4>
                </div>

                <figure>
                  {isVideo(image) ? (
                    <video controls muted>
                      <source
                        src={image}
                        type={`video/${video.split(".").pop()}`}
                      />
                      Seu navegador não suporta o elemento de vídeo.
                    </video>
                  ) : (
                    <img alt={productName} src={image} />
                  )}
                </figure>

                <a
                  href={supplierInternationalLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Visitar Fornecedor</p>
                </a>
              </li>
            ) : (
              Array.from({ length: 12 }).map((_, index) => (
                <li className="free-plan" key={index}>
                  <div className="playdrop-logo">
                    <img src={playdropIcon} alt="Ícone da Playdrop" />
                    <h4>Gold</h4>
                  </div>

                  <h5>Disponível no Plano Gold</h5>

                  <button onClick={() => navigate("/dashboard/planos")}>
                    Assinar plano
                  </button>
                </li>
              ))
            )
          ) : isSuppliers === "international" && vendors.length > 0 ? (
            vendors.map((item, index) => {
              if (data.id_planos === 4 && index >= 0) {
                return (
                  <li className="free-plan" key={index}>
                    <div className="playdrop-logo">
                      <img src={playdropIcon} alt="Ícone da Playdrop" />
                      <h4>Gold</h4>
                    </div>
                    <h5>Disponível no Plano Gold</h5>
                    <button onClick={() => navigate("/dashboard/planos")}>
                      Assinar plano
                    </button>
                  </li>
                );
              }
              // Renderiza o item normalmente
              return (
                <li key={item.id}>
                  <div className="icon">
                    <div>
                      <img src={item.ImgShop} alt={item.NameShop} />
                    </div>
                    <h4>{item.NameShop}</h4>
                  </div>
                  <img src={item.ImgProduct} alt={item.NameProduct} />
                  <a href={item.LinkShop} target="_blank" rel="noreferrer">
                    <p>Visitar Fornecedor</p>
                  </a>
                </li>
              );
            })
          ) : isSuppliers === "international" &&
            supplierInternationalLink !== "Sem fornecedor internacional" &&
            vendors.length <= 0 ? (
            <h4>Esse produto não possui fornecedores internacionais</h4>
          ) : null}

          {/* {isSuppliers === "international" && vendors.length > 0 ? (
            vendors.map((item, index) => {
              if (data.id_planos === 4 && index >= 4) {
                // Renderiza o card bloqueado para os itens além do 4º
                return (
                  <li className="free-plan" key={index}>
                    <div className="playdrop-logo">
                      <img src={playdropIcon} alt="Ícone da Playdrop" />
                      <h4>Gold</h4>
                    </div>
                    <h5>Disponível no Plano Gold</h5>
                    <button onClick={() => navigate("/dashboard/planos")}>
                      Assinar plano
                    </button>
                  </li>
                );
              }
              // Renderiza o item normalmente
              return (
                <li key={item.id}>
                  <div className="icon">
                    <div>
                      <img src={item.ImgShop} alt={item.NameShop} />
                    </div>
                    <h4>{item.NameShop}</h4>
                  </div>
                  <img src={item.ImgProduct} alt={item.NameProduct} />
                  <a href={item.LinkShop} target="_blank" rel="noreferrer">
                    <p>Visitar Fornecedor</p>
                  </a>
                </li>
              );
            })
          ) : isSuppliers === "internacional" && vendors.length <= 0 ? (
            supplierInternationalLink !== "Sem fornecedor internacional" ? (
              <li className="brazilian-product">
                <div className="icon">
                  <div className="brazilian-icon">
                    <TbWorldCheck size="2rem" color="#3058a4" />
                  </div>

                  <h4>{productName}</h4>
                </div>

                <figure>
                  {isVideo(image) ? (
                    <video controls muted>
                      <source
                        src={image}
                        type={`video/${video.split(".").pop()}`}
                      />
                      Seu navegador não suporta o elemento de vídeo.
                    </video>
                  ) : (
                    <img alt={productName} src={image} />
                  )}
                </figure>

                <a
                  href={supplierInternationalLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Visitar Fornecedor</p>
                </a>
              </li>
            ) : (
              <h4>Esse produto não possui fornecedores internacionais</h4>
            )
          ) : null} */}

          {isSuppliers === "brazilian" &&
          data.id_planos !== 4 &&
          supplierLink !== "Sem fornecedor nacional" ? (
            <li className="brazilian-product">
              <div className="icon">
                <div className="brazilian-icon">
                  <GiBrazilFlag size="2rem" color="#ffff00" />
                </div>

                <h4>{productName}</h4>
              </div>

              <figure>
                {isVideo(image) ? (
                  <video controls muted>
                    <source
                      src={image}
                      type={`video/${video.split(".").pop()}`}
                    />
                    Seu navegador não suporta o elemento de vídeo.
                  </video>
                ) : (
                  <img alt={productName} src={image} />
                )}
              </figure>

              <a href={supplierLink} target="_blank" rel="noreferrer">
                <p>Visitar Fornecedor</p>
              </a>
            </li>
          ) : isSuppliers === "brazilian" && data.id_planos === 4 ? (
            Array.from({ length: 12 }).map((_, index) => (
              <li className="free-plan" key={index}>
                <div className="playdrop-logo">
                  <img src={playdropIcon} alt="Ícone da Playdrop" />
                  <h4>Gold</h4>
                </div>

                <h5>Disponível no Plano Gold</h5>

                <button onClick={() => navigate("/dashboard/planos")}>
                  Assinar plano
                </button>
              </li>
            ))
          ) : isSuppliers === "brazilian" &&
            data.id_planos !== 4 &&
            supplierLink === "Sem fornecedor nacional" ? (
            <h4>Esse produto não possui fornecedores nacionais</h4>
          ) : null}
        </>
      )}
    </SuppliersCardContent>
  );
};
