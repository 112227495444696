import * as L from "./styles";

import { Header } from "../Header";
import { Outlet } from "react-router-dom";
import { DesktopDrawer } from "../Drawer/DesktopDrawer";
import { DrawerAdmPanelDesk } from "../Drawer/DrawerAdmPanelDesk";

import { useAuth } from "../../contexts/Auth";

export const Layout = () => {
  const { data } = useAuth();

  const signed = data && Object.values(data).length > 0;

  return (
    <L.Container>
      <L.Content>
        <Header />

        <L.Grid>
          <Outlet />
        </L.Grid>

        <L.Flex>
          <div className="support">
            <div></div>
          </div>
          {signed && data.perfil === "admin" ? (
            <DrawerAdmPanelDesk />
          ) : (
            <DesktopDrawer />
          )}
          <Outlet />
        </L.Flex>
      </L.Content>
    </L.Container>
  );
};
