import { BsCurrencyDollar } from "react-icons/bs";
import { ModalHeader } from "../ModalHeader";
import { Modal } from "../index";
import { ModalContentChoosePlan } from "../../../pages/Plans/ModalContentChoosePlan";

export const ModalChoosePlan = ({ isOpen, onClose, plan }) => {
  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="80rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BsCurrencyDollar} color="#fff" />
            <ModalHeader.Title title="Estamos quase lá!! Conclua seu pedido." />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>

        <Modal.Body>
          <ModalContentChoosePlan plan={plan} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
