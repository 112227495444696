import styled from "styled-components";

export const Content = styled.div`
  .store-title {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .create-store {
      width: 100%;
      height: 3rem;
      margin: 0 auto;
      color: #fff;
      font-size: 1.25rem;
      border-radius: 0.32rem;
      background: linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%);

      &:hover {
        opacity: 0.8;
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;

      .create-store {
        width: 300px;
      }
    }
  }

  .themes {
    width: 100%;

    .category {
      h4 {
        margin-bottom: 1rem;
      }
    }
  }
`;

export const ContentOfThemes = styled.ul`
  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.2rem;
`;

export const Card = styled.div`
  width: 100%;

  position: relative;

  margin-bottom: 1.5rem;

  border: 1px solid #f5f5f5;
  box-shadow: 0px 8px 12px -1.7px #e6eeff;
  border-radius: 10px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  .crown {
    position: absolute;
    top: -1.5rem;
    right: 0;
  }

  img {
    width: 100%;
    height: 250px;

    border-radius: 10px 10px 0 0;
  }

  .content {
    padding: 1rem;

    h4 {
      color: #4f4f4f;
      text-align: center;
      font-size: 1.25rem;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }

  &:hover {
    transform: scale(1.1);
    transition: ease-in 0.2s;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    width: 450px;
  }
  @media (min-width: 1280px) {
    width: 500px;
  }
  @media (min-width: 1600px) {
    width: 570px;
  }
`;

export const ModalContentTheme = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  h4 {
    color: #3058a4;
    font-size: 1.25rem;
    text-align: center;
    font-weight: 600;
  }

  .websites-content {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;

    li {
      height: 45px;
      padding: 10px;

      display: flex;
      align-items: center;
      gap: 0.3rem;

      cursor: pointer;

      label,
      input {
        cursor: pointer;
      }

      color: #fff;
      border-radius: 10px;
      background-color: #3058a4;
    }
  }

  h5 {
    color: #4f4f4f;
    font-size: 1.125rem;
    text-align: center;
    font-weight: 500;
  }

  iframe {
    width: 100%;
    height: 300px;
    border-radius: 10px;
  }

  .send-theme {
    width: 350px;
    height: 50px;

    color: #fff;
    font-size: 1.25rem;
    border-radius: 10px;
    background-color: #fb6b03;

    &:hover {
      opacity: 0.7;
    }
  }

  .free-plan-content {
    width: 100%;
    height: 200px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      h4 {
        color: #3058a4;
        font-size: 1.25rem;
        font-weight: 500;
      }
    }

    p {
      color: #4f4f4f;
      text-align: center;
      font-weight: 500;
    }

    .button-block-content {
      width: 350px;
      height: 50px;

      color: #fff;
      font-size: 1.25rem;
      border-radius: 10px;
      background-color: #fb6b03;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const ConfigStoreContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  h4 {
    color: #3058a4;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .free-plan-content {
    width: 100%;
    height: 200px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      h4 {
        color: #3058a4;
        font-size: 1.25rem;
        font-weight: 500;
      }
    }

    p {
      color: #4f4f4f;
      text-align: center;
      font-weight: 500;
    }

    .button-block-content {
      width: 350px;
      height: 50px;

      color: #fff;
      font-size: 1.25rem;
      border-radius: 10px;
      background-color: #fb6b03;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .create-store {
    width: 250px;
    height: 3rem;
    color: #fff;
    font-size: 1.25rem;
    border-radius: 0.32rem;
    background: linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%);

    &:hover {
      opacity: 0.8;
    }
  }

  .generate-name {
    color: #4f4f4f;
    font-weight: 500;

    &:hover {
      color: #3058a4;
    }
  }

  .third-step {
    width: 100%;

    div {
      width: 100%;
      margin-top: 0.3rem;
      margin-bottom: 0.5rem;

      display: flex;
      justify-content: flex-start;
    }

    iframe {
      width: 100%;
      height: 300px;
    }

    button {
      width: 100%;
      height: 50px;
      margin-top: 1rem;
      margin-bottom: 1rem;

      color: #3058a4;
      font-size: 1.25rem;
      font-weight: 500;

      color: #fff;
      border-radius: 10px;
      background: linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%);

      &:hover {
        transition: ease-in 0.2s;
        opacity: 0.8;
      }
    }
  }
`;

export const StoreConnectionContainer = styled.div`
  width: 100%;

  h4 {
    margin-bottom: 0.5rem;

    color: #4f4f4f;
    font-weight: 500;
  }

  span {
    color: #4f4f4f;
    font-weight: 500;
  }

  form {
    display: flex;
    flex-direction: column;

    .to-third-step {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      margin-top: 0.5rem;
      margin-bottom: 1rem;

      div {
        svg {
          color: #4f4f4f;
        }
      }

      span {
        font-size: 1.125rem;
        font-weight: 500;
      }

      &:hover {
        cursor: pointer;

        div {
          svg {
            color: #3058a4;
          }
        }
        span {
          color: #3048a4;
        }
      }
    }

    button {
      width: 100%;
      height: 50px;
      margin-top: 1rem;
      margin-bottom: 1rem;

      color: #fff;
      font-size: 1.25rem;
      font-weight: 500;

      border-radius: 10px;
      background: linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%);

      &:hover {
        transition: ease-in 0.2s;
        opacity: 0.8;
      }
    }
  }
`;

export const ThemeContent = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  h4 {
    color: #3058a4;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .password {
    width: 200px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0px 8px 12px -1.7px #e6eeff;
    border-radius: 10px;
    background-color: #fff;

    color: #3058a4;
    font-size: 1.5rem;
    font-weight: 500;
  }
`;
