import { Link, useNavigate } from "react-router-dom";

import { Link as LinkChakra } from "@chakra-ui/react";

import { Input } from "../../components/Reusable/Input";
import { ConfigStoreContainer } from "./styles";

import { useState } from "react";
import { StoreConnectionContent } from "./StoreConnectionContent";
import { GoBack } from "../../components/Reusable/GoBack";
import { useWebsitesClient } from "../../contexts/Websites/WebsitesContextClient";
import { useBacklogs } from "../../contexts/Backlogs";
import { FaShopLock } from "react-icons/fa6";

export const ContentConfigStore = ({
  onClose,
  websitesUser,
  maxSitesAllowed,
}) => {
  const [step, setStep] = useState("first_step");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { createWebsite } = useWebsitesClient();
  const { backlogUpdateAdmin } = useBacklogs();

  const [storeData, setStoreData] = useState({
    storeName: "",
    domain: "",
    token: "",
  });

  const [storeNameError, setStoreNameError] = useState(false);

  const handleCreateWebsite = (ev, storeData) => {
    ev.preventDefault();

    setLoading(true);

    const backLogObject = {
      data_pendencia: new Date(),
      titulo_pendencia: `Criação de loja para ${storeData.storeName}`,
      status_pendencias: "pendente",
      pendencias: "Criação de nova loja",
    };

    createWebsite(storeData)
      .then((_) => setLoading(false))
      .catch((_) => setLoading(false));

    // Atualiza o Backlog do administrador com uma nova pendência.
    backlogUpdateAdmin(backLogObject);

    onClose();
  };

  return (
    <ConfigStoreContainer>
      {maxSitesAllowed > 0 && websitesUser >= maxSitesAllowed ? (
        <div className="free-plan-content">
          <div>
            <FaShopLock fill="#fb6b03" size="3rem" />
            <h4>Você já possui {websitesUser} lojas ativas no momento</h4>
          </div>

          <p>
            De acordo com o seu plano, é necessário realizar upgrade para poder
            criar uma nova loja
          </p>

          <button
            className="button-block-content"
            onClick={() => navigate("/dashboard/planos")}
          >
            Fazer upgrade
          </button>
        </div>
      ) : (
        <>
          {step === "first_step" ? (
            <>
              <h4>Informe o nome de seu negócio</h4>

              <Input
                h="55px"
                color="#4F4F4F"
                label="Nome da loja"
                border="2px solid #3058A5"
                onChange={(ev) =>
                  setStoreData({ ...storeData, storeName: ev.target.value })
                }
                placeholder="Digite o nome de sua loja"
                value={storeData.storeName}
              />

              {storeNameError ? (
                <span>
                  Para prosseguir é necessário digitar acima o nome de sua loja
                </span>
              ) : null}

              <button
                className="create-store"
                onClick={() => {
                  if (storeData.storeName !== "") {
                    setStep("second_step");
                    setStoreNameError(false);
                  } else {
                    setStoreNameError(true);
                  }
                }}
              >
                Criar loja
              </button>

              <Link to="/dashboard/gerador-de-nomes">
                <span className="generate-name">
                  Caso não possua um nome para sua loja, Utilize a nossa IA
                </span>
              </Link>
            </>
          ) : null}

          {step === "second_step" ? (
            <StoreConnectionContent
              setStep={setStep}
              storeData={storeData}
              setStoreData={setStoreData}
              handleCreateWebsite={handleCreateWebsite}
              loading={loading}
            />
          ) : null}

          {step === "third_step" ? (
            <div className="third-step">
              <div>
                <GoBack onClick={() => setStep("second_step")} />
              </div>

              <iframe
                allowFullScreen
                title="Como criar sua conta"
                src="https://iframe.mediadelivery.net/embed/299009/433c8cf3-5cb9-49e5-a1ec-1f364f81aa70?autoplay=true&loop=false&muted=false&preload=true&responsive=true"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />

              <LinkChakra
                rel="noreferrer"
                href="https://www.shopify.com/br/avaliacao-gratuita?irclickid=Su1xz1znJxyKWIzRdpw9j13PUkC09AU3qSLMTA0&irgwc=1&partner=3198336&affpt=excluded&utm_channel=affiliates&utm_source=3198336-impact&utm_medium=cpa"
                target="_blank"
              >
                <button>Criar conta na Shopify</button>
              </LinkChakra>
            </div>
          ) : null}

          {step === "fourth_step" ? (
            <div className="third-step">
              <div>
                <GoBack onClick={() => setStep("second_step")} />
              </div>

              <iframe
                allowFullScreen
                title="Como obter o token"
                src="https://iframe.mediadelivery.net/embed/299009/3f7d07b4-56ca-4fda-9f0c-e6050acf50a2?autoplay=true&loop=false&muted=false&preload=true&responsive=true"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
            </div>
          ) : null}
        </>
      )}
    </ConfigStoreContainer>
  );
};
