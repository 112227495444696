import * as P from "./styles";

// Components
import {
  Flex,
  Center,
  ListItem,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";

// Hooks
import { useAuth } from "../../contexts/Auth";

// Icons
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { currencyFormat } from "../../utils/currencyFormat";
import { ModalChoosePlan } from "../../components/Modal/Plans/ModalChoosePlan";

export const CardPlan = ({ plan }) => {
  const { data } = useAuth();

  const {
    isOpen: isOpenModalChoosePlan,
    onOpen: onOpenModalChoosePlan,
    onClose: onCloseModalChoosePlan,
  } = useDisclosure();

  // Separação da lista de benefícios ativos
  const activeBenefits = plan.ativos.split(",");

  // Separação da lista de benefícios inativos / não inclusos
  const inativesBenefits = plan.inativos !== "" && plan.inativos.split(",");

  return (
    <P.CardPlanContent bgSchema={plan.id === 9}>
      {plan.id === 4 && (
        <div className="best-seller">Ideal para iniciantes</div>
      )}
      {plan.id === 5 && (
        <div className="best-seller">Ideal para começar a vender</div>
      )}
      {plan.id === 9 && (
        <div className="best-seller">Ideal para escalar sua empresa</div>
      )}

      <h4 className="title-plan">{plan.plano}</h4>

      <UnorderedList ml="0" mb="6">
        <>
          {activeBenefits.map((benefits, index) => {
            return (
              <Flex key={index} alignItems="baseline">
                <Center>
                  <BsCheckLg size="1rem" fill="#03AC00" />
                </Center>
                <ListItem
                  ml="2"
                  fontWeight="400"
                  fontStyle="normal"
                  fontFamily="Poppins"
                  fontSize="1.2rem"
                  color={plan.plano === "Plano Premium" ? "#fff" : "#4f4f4f"}
                >
                  {benefits}
                </ListItem>
              </Flex>
            );
          })}
          {inativesBenefits &&
            inativesBenefits.map((benefits, index) => {
              return (
                <Flex key={index} alignItems="baseline">
                  <Center>
                    <BsXLg size="1rem" fill="#F44336" />
                  </Center>
                  <ListItem
                    fontSize="1.2rem"
                    color="#B0B0B0"
                    fontWeight="400"
                    fontFamily="Poppins"
                    fontStyle="normal"
                    ml="2"
                  >
                    {benefits}
                  </ListItem>
                </Flex>
              );
            })}
        </>
      </UnorderedList>

      {plan.id !== 4 && (
        <div className="price-gold">
          <div className="old-price">
            <p>{plan.id === 5 ? "R$ 159,90" : "R$ 299,99"}</p>
            <div>{plan.id === 5 ? "45% off" : "55% off"}</div>
          </div>

          <div className="new-price">
            <span>A partir de:</span>
            <div>
              <p>{currencyFormat(plan.mensalidades)}</p>
              <span>/mês</span>
            </div>
          </div>
        </div>
      )}

      {plan.id === 4 && (
        <div className="free-plan" position="relative">
          <div className="top" />
          <div className="back">Plano gratuito</div>
        </div>
      )}

      {plan.id !== 4 ? (
        <P.StyledButton
          disabled={plan.id === data.id_planos ? true : false}
          activePlan={plan.id === data.id_planos ? true : false}
          onClick={() => onOpenModalChoosePlan()}
        >
          {plan.id === data.id_planos ? "Plano Ativo" : "Assinar"}
        </P.StyledButton>
      ) : null}

      <ModalChoosePlan
        isOpen={isOpenModalChoosePlan}
        onClose={onCloseModalChoosePlan}
        plan={plan}
      />
    </P.CardPlanContent>
  );
};
