import { Spinner } from "@chakra-ui/react";
import { Input } from "../../components/Reusable/Input";
import { StoreConnectionContainer } from "./styles";

import { FaArrowUpRightFromSquare } from "react-icons/fa6";

export const StoreConnectionContent = ({
  setStep,
  storeData,
  setStoreData,
  handleCreateWebsite,
  loading,
}) => {
  return (
    <StoreConnectionContainer>
      <form>
        <Input
          label="Domínio principal da sua loja na Shopify"
          placeholder="Ex: minhaloja.myshopify.com"
          defaultValue={`https://${storeData.domain}`}
          onChange={(ev) =>
            setStoreData({ ...storeData, domain: ev.target.value })
          }
        />

        <div className="to-third-step" onClick={() => setStep("third_step")}>
          <div>
            <FaArrowUpRightFromSquare size="1.25rem" />
          </div>
          <span>Se você não possui uma conta na Shopify, Clique aqui!</span>
        </div>

        <Input
          label="Token de API:"
          placeholder="Cole o token gerado aqui"
          onChange={(ev) =>
            setStoreData({ ...storeData, token: ev.target.value })
          }
        />

        <div className="to-third-step" onClick={() => setStep("fourth_step")}>
          <div>
            <FaArrowUpRightFromSquare size="1.25rem" />
          </div>
          <span>
            Acesse o guia na central de ajuda para saber como obter o token
          </span>
        </div>

        <button
          onClick={(ev) =>
            handleCreateWebsite(ev, {
              ...storeData,
              domain: `${storeData.domain}`,
            })
          }
        >
          {loading ? <Spinner color="#fff" /> : "Finalizar cadastro na loja"}
        </button>
      </form>
    </StoreConnectionContainer>
  );
};
