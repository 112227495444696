import { useNavigate } from "react-router-dom";
import { currencyFormat } from "../../utils/currencyFormat";
import { ContentChoosePlan } from "./styles";
import { Link, useToast } from "@chakra-ui/react";
import { useAuth } from "../../contexts/Auth";
import { apiAuth } from "../../services/api";

const goldPlan = [
  { id: 1, month: 1, price: 129.9 },
  { id: 2, month: 3, price: 109.9, discount: 60 },
  { id: 3, month: 12, price: 89.9, discount: 480 },
];

const premiumPlan = [
  { id: 4, month: 1, price: 299.9 },
  { id: 5, month: 3, price: 269.9, discount: 90 },
  { id: 6, month: 12, price: 139.9, discount: 1929 },
];

const checkoutLinks = {
  5: {
    1: "https://pay.kirvano.com/b1f4c8a2-d71a-4ff3-929c-1092b966d0ea",
    3: "https://pay.kirvano.com/7ac6f554-bdbb-4947-b3df-53e52932b182",
    12: "https://pay.kirvano.com/b6aa07e7-8d0d-4106-89c2-f39021c77016",
  },
  9: {
    1: "https://pay.kirvano.com/4d360586-2361-4f0b-aa90-3d953249a5e3",
    3: "https://pay.kirvano.com/435f2eb1-acc5-46e3-8ef7-53a7c93cad76",
    12: "https://pay.kirvano.com/bd8001be-32ca-48aa-acd3-991cbe4c30d9",
  },
};

function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

export const ModalContentChoosePlan = ({ plan }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { data, loadUser } = useAuth();

  const updatePlan = async (timePlan, planId) => {
    const date = new Date();
    const days = timePlan === 1 ? 30 : timePlan === 3 ? 90 : 365;

    const dueDate = addDays(date, days);

    const body = {
      id: data.id,
      id_login: data.id,
      id_planos: Number(planId),
      key_planos: Number(planId),
      time_plan: timePlan === 1 ? 30 : timePlan === 3 ? 90 : 365,
      plano_status: "ativo",
      plano_sub_status: "aguardando_pagamento",
      plano_data_venc: dueDate.toISOString().slice(0, 10),
    };

    try {
      await apiAuth
        .post(
          `/editarperfil?id=${data.id}`,
          { data: JSON.stringify(body) },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((_) => {
          navigate("/dashboard/construtor-de-lojas");
          loadUser(data.id);
        });
    } catch (error) {
      toast({
        title: "Opss...",
        description: "Algo deu errado!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <ContentChoosePlan>
      <h5>
        Plano selecionado: <b>{plan.plano}</b>
      </h5>

      <h4>Escolha um período</h4>

      <ul>
        {plan.id === 5
          ? goldPlan.map((item) => (
              <div className="content-plan" key={item.id}>
                <div>
                  <h6>
                    {item.month > 1
                      ? `${item.month} meses`
                      : `${item.month} mês`}
                  </h6>
                  <span>
                    {plan.plano}
                    {item.month === 1
                      ? " (Mensal)"
                      : item.month === 3
                      ? " (Trimestral)"
                      : " (Anual)"}
                  </span>
                </div>

                <div>
                  <p>{currencyFormat(item.price)}</p>
                  <span>/mês</span>
                </div>

                <div className="go-to-checkout">
                  <Link
                    rel="noreferrer"
                    href={checkoutLinks[plan.id]?.[item.month]}
                    target="_blank"
                  >
                    <button onClick={() => updatePlan(item.month, plan.id)}>
                      Ir para Pagamento
                    </button>
                  </Link>
                  <p className="checkout-safe">Checkout 100% Seguro</p>
                </div>

                <p className="terms">
                  Ao clicar, você concorda com nossos{" "}
                  <Link>Termos e Condições</Link>.
                </p>

                {item.month !== 1 && (
                  <span className="economic-info">
                    ECONOMIZE {currencyFormat(item.discount)}
                  </span>
                )}
              </div>
            ))
          : premiumPlan.map((item) => (
              <div className="content-plan" key={item.id}>
                <div>
                  <h6>
                    {item.month > 1
                      ? `${item.month} meses`
                      : `${item.month} mês`}
                  </h6>
                  <span>
                    {plan.plano}
                    {item.month === 1
                      ? " (Mensal)"
                      : item.month === 3
                      ? " (Trimestral)"
                      : " (Anual)"}
                  </span>
                </div>

                <div>
                  <p>{currencyFormat(item.price)}</p>
                  <span>/mês</span>
                </div>

                <div className="go-to-checkout">
                  <Link
                    rel="noreferrer"
                    href={checkoutLinks[plan.id]?.[item.month]}
                    target="_blank"
                  >
                    <button onClick={() => updatePlan(item.month, plan.id)}>
                      Ir para Pagamento
                    </button>
                  </Link>
                  <p className="checkout-safe">Checkout 100% Seguro</p>
                </div>

                <p className="terms">
                  Ao clicar, você concorda com nossos{" "}
                  <Link>Termos e Condições</Link>.
                </p>

                {item.month !== 1 && (
                  <span className="economic-info">
                    ECONOMIZE {currencyFormat(item.discount)}
                  </span>
                )}
              </div>
            ))}
      </ul>
    </ContentChoosePlan>
  );
};
