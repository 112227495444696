import * as C from "./styles";

import { v4 as uuidv4 } from "uuid";

import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCopyGenerator } from "../../hooks/useCopyGenerator";

import { FaRegCopyright } from "react-icons/fa";

import { Title } from "../../components/Reusable/Title";
import { Button } from "../../components/Button";
import { Spinner } from "../../components/Spinner";
import { Select, useDisclosure, useToast } from "@chakra-ui/react";
import { Container } from "../../components/Reusable/Container";
import { Input } from "../../components/Reusable/Input";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { copyGeneratorSchema } from "../../schemas/schemas";
import { useAuth } from "../../contexts/Auth";
import { ModalUpdatePlan } from "../../components/Modal/CopyGenerator/ModalUpdatePlan";
import { useCredits } from "../../contexts/Credits";
import { FiArrowUpRight } from "react-icons/fi";
import { CustomSelect } from "../../components/CustomSelect";

export const CopyGenerator = () => {
  const [loading, setLoading] = useState(false);
  const [selections, setSelections] = useState([]);

  const toast = useToast();

  const { data } = useAuth();
  const { productName } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { credits, discountCoinWallet } = useCredits();

  const signed = data && Object.values(data).length > 0;

  const isBasicPlan = signed && data.id_planos === 4;

  const navigate = useNavigate();

  const [name, setName] = useState({
    nameProduct: productName !== "" ? productName : "",
  });

  const filteredCreditsCopy =
    credits.length > 0 &&
    credits?.find((item) => item.category === "gerar_copy");

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(copyGeneratorSchema),
  });

  const { storedValues, generateResponse } = useCopyGenerator(setLoading);

  const stringValue = storedValues && storedValues.answer;

  const arrValue =
    stringValue !== undefined && stringValue.trim().split("\n\n");

  const handleGenerateNewCopy = (generateData) => {
    if (!signed) return;

    if (isBasicPlan) {
      const idCopy = uuidv4().slice(0, 5);

      if (filteredCreditsCopy.creditos <= 0) {
        onOpen();
      }

      if (filteredCreditsCopy.creditos > 0) {
        setLoading(true);

        const keyWords = selections.join(", ");

        const { style, additional_note, copy_size } = generateData;

        const question = `Gerar uma Copy para o produto: ${name.nameProduct}, com o estilo de comunicação: ${style}, com as palavras chave: ${keyWords}, com as seguintes notas adicionais: ${additional_note}, com o tamanho: ${copy_size}, a copy deverá ser retornada em formato de lista, cada começo de lista (parágrafo) deverá vir com um emoji, e quando uma lista acabar adicione uma quebra de linha para usar no react.`;

        generateResponse(question)
          .then((_) => setLoading(false))
          .catch((_) => setLoading(false));

        discountCoinWallet(filteredCreditsCopy.id, 5, idCopy, "copy");
      }
    } else {
      setLoading(true);

      const keyWords = selections.join(", ");

      const { style, additional_note, copy_size } = generateData;

      const question = `Gerar uma Copy para o produto: ${name.nameProduct}, com o estilo de comunicação: ${style}, com as palavras chave: ${keyWords}, com as seguintes notas adicionais: ${additional_note}, com o tamanho: ${copy_size}, a copy deverá ser retornada em formato de lista, cada começo de lista (parágrafo) deverá vir com um emoji, e quando uma lista acabar adicione uma quebra de linha para usar no react.`;

      generateResponse(question)
        .then((_) => setLoading(false))
        .catch((_) => setLoading(false));
    }
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text);

    toast({
      title: "Copiado",
      description: "Copiado para área de transferência!",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
  };

  return (
    <Container>
      <C.Content>
        <Title
          headingName="Crie textos com o nosso gerador de Copy"
          caption="Preencha os campos abaixo para gerar textos de copy."
        >
          <FaRegCopyright fill="#3058A5" size="28px" />
        </Title>

        <hr />

        {isBasicPlan && (
          <div className="info-credits">
            {filteredCreditsCopy !== undefined &&
            filteredCreditsCopy.creditos === 0 ? (
              <>
                <p>Opps, saldo para gerar copy acabou...</p>
                <div onClick={() => navigate("/dashboard/planos")}>
                  <FiArrowUpRight size="1.5rem" color="#3058a4" />
                </div>
              </>
            ) : (
              <p>
                Você pode gerar
                <span>
                  {filteredCreditsCopy && filteredCreditsCopy.creditos / 5}
                </span>
                copys
              </p>
            )}
          </div>
        )}

        <C.Form onSubmit={handleSubmit(handleGenerateNewCopy)}>
          <Input
            error={errors.name}
            label="Nome do Produto"
            onChange={(ev) =>
              setName({ ...name, nameProduct: ev.target.value })
            }
            defaultValue={productName && productName}
            placeholder="Exemplo: Liquidificador Elétrico"
          />

          <C.Flex>
            <div className="grid-select">
              <label htmlFor="">Escolha um estilo de comunicação</label>
              <Select
                w="100%"
                h="50px"
                bg="#fff"
                cursor="pointer"
                color="#4F4F4F"
                borderWidth="2px"
                borderRadius="4px"
                {...register("style")}
                _hover={{ opacity: 0.7 }}
                placeholder={"Escolha uma opção"}
                _placeholder={{ color: "#4F4F4F" }}
                borderColor={errors.style ? "#BF4635" : "#3058a4"}
              >
                <option value="Entusiasmado">Entusiasmado</option>
                <option value="Amigável">Amigável</option>
                <option value="Persuasivo">Persuasivo</option>
                <option value="Descritivo">Descritivo</option>
                <option value="Urgente">Urgente</option>
              </Select>
            </div>

            <div className="grid-select">
              <label htmlFor="">Tamanho da copy</label>
              <Select
                w="100%"
                h="50px"
                bg="#fff"
                cursor="pointer"
                color="#4F4F4F"
                borderWidth="2px"
                borderRadius="4px"
                _hover={{ opacity: 0.7 }}
                {...register("copy_size")}
                placeholder={"Escolha uma opção"}
                _placeholder={{ color: "#4F4F4F" }}
                borderColor={errors.copy_size ? "#BF4635" : "#3058a4"}
              >
                <option value="Curta">Curta</option>
                <option value="Média">Média</option>
                <option value="Longa">Longa</option>
              </Select>
            </div>
          </C.Flex>

          <CustomSelect selections={selections} setSelections={setSelections} />

          <Input
            error={errors.additional_note}
            label="Notas adicionais"
            {...register("additional_note")}
            placeholder="Exemplo: O valor do produto é de R$ 50,00"
          />

          <Button type="submit">{loading ? <Spinner /> : "Gerar Copy"}</Button>

          <h4>Resultado:</h4>

          {loading ? (
            <div className="copy-container">
              <div className="is-loading">
                🤯 Gerando uma copy que vai deixar o Mark Zuckerberg sem reação
                🤯
              </div>
            </div>
          ) : Object.values(storedValues).length > 0 ? (
            <div className="copy-container">
              {arrValue.map((text) => (
                <p key={text}>{text}</p>
              ))}
            </div>
          ) : (
            <div className="copy-container">
              <p>Sua copy aparecerá aqui</p>
            </div>
          )}

          {Object.values(storedValues).length > 0 ? (
            <C.Flex>
              <button
                type="button"
                onClick={() => copyText(storedValues.answer)}
              >
                Copiar Texto
              </button>
              <button type="submit" className="blue">
                {loading ? <Spinner /> : "Gerar outra Copy"}
              </button>
              <button type="button" onClick={() => reset()}>
                Limpar os campos
              </button>
            </C.Flex>
          ) : null}
        </C.Form>
      </C.Content>

      <ModalUpdatePlan isOpen={isOpen} onClose={onClose} />
    </Container>
  );
};
